import React, {useState} from "react";

import { Link } from "gatsby"
import PropTypes from "prop-types"

import Typography from '@material-ui/core/Typography';
import BackDrop from '@material-ui/core/BackDrop';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';


const Navigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  return (
    <header>
      <div className="site_header">
      <Link to="/">
        <div className="site_branding">
          <Typography component="h1" variant="h5" style={{fontWeight: 600}}>
            Beverly Rafferty 
          </Typography>
          <Typography component="h1" variant="h5" paragraph>
            Fine Art
          </Typography>
        </div>
      </Link>


        <div className='desktop_navigation'>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <MenuList>
              <MenuItem className="navigation_item" selected={(url.search('/work/southwestern') > -1)}><Link to="/work/southwestern"><span>Southwestern/Western</span></Link></MenuItem>
              <MenuItem className="navigation_item" selected={(url.search('/work/waterscapes') > -1)}><Link to="/work/waterscapes"><span>Waterscapes</span></Link></MenuItem>
              <MenuItem className="navigation_item" selected={(url.search('/work/eastern') > -1)}><Link to="/work/eastern"><span>Eastern U.S.</span></Link></MenuItem>
              <MenuItem className="navigation_item" selected={(url.search('/work/foreign') > -1)}><Link to="/work/foreign"><span>Foreign Places</span></Link></MenuItem>
              <MenuItem className="navigation_item" selected={(url.search('/work/commissions') > -1)}><Link to="/work/commissions"><span>Commissions</span></Link></MenuItem>
            </MenuList>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>
            <MenuList>
              <MenuItem className="navigation_item"><Link to="/exhibitions"><span>Exhibitions</span></Link></MenuItem>
              <MenuItem className="navigation_item" selected={(url.search('/about') > -1)}><Link to="/about"><span>About</span></Link></MenuItem>
              <MenuItem className="navigation_item" selected={(url.search('/contact') > -1)}><Link to="/contact"><span>Contact</span></Link></MenuItem>
            </MenuList>
          </div>

          {/* social links */}
        </div>

        <div className='mobile_navigation'>
          <Button 
            variant="contained" 
            color="primary" 
            size="small" 
            style={{height: 40, textTransform: 'capitalize', marginTop: 5}}
            onClick={toggleMobileMenu}
          >
            Menu
          </Button>
          <BackDrop open={mobileMenuOpen} className='mobile_navigation_menu' onClick={toggleMobileMenu} style={{zIndex: 999}}>
            <Button 
              variant="contained" 
              color="primary" 
              size="small" 
              style={{alignSelf: 'flex-end', marginRight: 20, marginBottom: 30, minWidth: 35}}
              onClick={toggleMobileMenu}
            >
              X
            </Button>
            <MenuItem className="mobile_navigation_item"><Link to="/work/southwestern"><span>Southwestern/Western</span></Link></MenuItem>
            <MenuItem className="mobile_navigation_item"><Link to="/work/waterscapes"><span>Waterscapes</span></Link></MenuItem>
            <MenuItem className="mobile_navigation_item"><Link to="/work/eastern"><span>Eastern U.S.</span></Link></MenuItem>
            <MenuItem className="mobile_navigation_item"><Link to="/work/foreign"><span>Foreign Places</span></Link></MenuItem>
            <MenuItem className="mobile_navigation_item"><Link to="/work/commissions"><span>Commissions</span></Link></MenuItem>
            {/* <MenuItem className="mobile_navigation_item"><Link to="/exhibitions"><span>Exhibitions</span></Link></MenuItem> */}
            <MenuItem className="mobile_navigation_item"><Link to="/about"><span>About</span></Link></MenuItem>
            <MenuItem className="mobile_navigation_item"><Link to="/contact"><span>Contact</span></Link></MenuItem>
          </BackDrop>
        </div>
      </div>
    </header>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
